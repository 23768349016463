import {LOCAL_STORAGE_EMPTY_CACHE, LOCAL_STORAGE_EXPIRE_ITEM, LOCAL_STORAGE_PRICES_ITEM} from '../const';
import {getExpireTime, getNowTimeStamp} from '../utils';

export const isStorageExpire = () => {
    return localStorage.getItem(LOCAL_STORAGE_EXPIRE_ITEM) <= getNowTimeStamp();
};

export const getPricesFromStorage = () => {
    const storagePrices = localStorage.getItem(LOCAL_STORAGE_PRICES_ITEM);

    return storagePrices && !isStorageExpire() ? storagePrices : LOCAL_STORAGE_EMPTY_CACHE;
};

export const savePricesToStorage = (prices) => {
    localStorage.setItem(LOCAL_STORAGE_PRICES_ITEM, prices);
    localStorage.setItem(LOCAL_STORAGE_EXPIRE_ITEM, getExpireTime().toString());
};
