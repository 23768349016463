import {DEFAULT_BOOK_URL, LINK_OPTION_DELIMITER, LINK_OPTION_PARAM} from '../const';

export const getLink = (linkOptions, bookUrl, elementModel, priceInfoModel) => {
    const hotel = elementModel.getHotel();
    const roomType = elementModel.getRoomType();
    const adults = elementModel.getAdults();
    const date = priceInfoModel.getData();
    const minLos = priceInfoModel.getMinLos();
    const offerCode = priceInfoModel.getRatePlaneCode();
    const options = linkOptions !== '' ? linkOptions.split(LINK_OPTION_DELIMITER) : null;
    const url = bookUrl ? bookUrl : DEFAULT_BOOK_URL;
    const urlParams = [];

    if (options === null) {
        return roomType ? `${url}?${LINK_OPTION_PARAM.room}=${roomType}` : `${url}?${LINK_OPTION_PARAM.hotel}=${hotel}`;
    }

    options.forEach((option) => {
        switch (option) {
            case 'hotel':
                urlParams.push(`${LINK_OPTION_PARAM.hotel}=${hotel}`);
                break;
            case 'room':
                if (roomType) {
                    urlParams.push(`${LINK_OPTION_PARAM.room}=${roomType}`);
                }
                break;
            case 'date':
                if (date) {
                    urlParams.push(`${LINK_OPTION_PARAM.date}=${date}`);
                }
                break;
            case 'nights':
                if (minLos) {
                    urlParams.push(`${LINK_OPTION_PARAM.nights}=${minLos}`);
                }
                break;
            case 'adults':
                if (adults) {
                    urlParams.push(`${LINK_OPTION_PARAM.adults}=${adults}`);
                }
                break;
            case 'offer':
                if (offerCode) {
                    urlParams.push(`${LINK_OPTION_PARAM.offer}=${offerCode}`);
                }
                break;
            default:
                break;
        }
    });

    return `${url}?${urlParams.join('&')}`;
};
