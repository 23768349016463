import {PRICES_CACHE_FILE} from '../const';

export const fetchPrices = async () => {
    const result = await fetch(PRICES_CACHE_FILE);

    if (!result.ok) {
        console.error('Could\'t fetch price');
    }

    return await result.json();
};
