import {fetchPrices} from '../modules/fetch-prices';
import {getPricesFromStorage, savePricesToStorage} from '../modules/local-storage';
import {isDemoMode} from '../utils';

const FETCH_PRICES_ERROR = 'PriceLoad load error:';

export class PriceLoad {
    constructor() {
        this._cachePrices = getPricesFromStorage();
    }

    isLoadNeeded() {
        return !this._cachePrices || isDemoMode();
    }

    load() {
        return fetchPrices()
            .then((prices) => {
                savePricesToStorage(JSON.stringify(prices));
                return prices;
            })
            .catch((error) => {
                console.log(FETCH_PRICES_ERROR);
                console.log(error);
            });
    }

    getFromCache() {
        return JSON.parse(this._cachePrices);
    }
}
