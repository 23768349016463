import {ROUND_OPTION} from '../../const';
import {isDemoMode, isUnsupportedLanguage, reverseString} from '../../utils';
import {LANG_SETTING} from '../../settings';

export const roundPrice = (price) => {
    if (LANG_SETTING[TL_PRICE_MODULE_LANG].UsePriceRound !== ROUND_OPTION.No) {
        switch (LANG_SETTING[TL_PRICE_MODULE_LANG].UsePriceRound) {
            case ROUND_OPTION.RoundDown:
                return Math.floor(price);
            case ROUND_OPTION.RoundUp:
                return Math.ceil(price);
            case ROUND_OPTION.RoundNearest:
                return Math.round(price);
            default:
                break;
        }
    }

    return price;
};

export const splitPrice = (price) => {
    if (LANG_SETTING[TL_PRICE_MODULE_LANG].UsePriceSplit !== false) {
        const priceString = (typeof price !== 'string') ? price.toString() : price;
        const priceEnding = priceString.match(/(([,.])\d+$)/) ? priceString.match(/(([,.])\d+$)/)[0] : '';
        const priceMain = priceString.replace(priceEnding, '');

        price = reverseString(priceMain)
            .match(/.{1,3}/g)
            .map((character) => {
                return reverseString(character);
            })
            .reverse()
            .join(' ');
        price += priceEnding;
    }

    return price;
};

export const changePriceDelimiter = (price) => {
    if (LANG_SETTING[TL_PRICE_MODULE_LANG].PriceDelimiter !== '') {
        price = price.toString().replace('.', LANG_SETTING[TL_PRICE_MODULE_LANG].PriceDelimiter);
    }

    return price;
};

export const getFormatPrice = (price) => {
    if (isDemoMode() || isUnsupportedLanguage()) {
        return price;
    }

    price = roundPrice(price);
    price = splitPrice(price);
    price = changePriceDelimiter(price);

    return price;
};
