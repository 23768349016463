import dayjs from 'dayjs';
import {
    UNSUPPORTED_LANG,
    LOCAL_STORAGE_CACHE_MEASURE,
    LOCAL_STORAGE_CACHE_VALUE
} from './const';

export const isDemoMode = () => {
    return typeof tlPriceLoadMode !== 'undefined' && tlPriceLoadMode === 'demo';
};

export const isUnsupportedLanguage = () => {
    return TL_PRICE_MODULE_LANG === UNSUPPORTED_LANG;
};

export const reverseString = (text) => {
    return text.split('').reverse().join('');
};

export const getNowTimeStamp = () => {
    return dayjs().unix();
};

export const getExpireTime = () => {
    return dayjs().add(LOCAL_STORAGE_CACHE_VALUE, LOCAL_STORAGE_CACHE_MEASURE).unix();
};
